/**
 * 路由组件出口文件
 */
import Loadable from 'react-loadable';
import Loading from './widget/Loading';
import BasicTable from './tables/BasicTables';
import AdvancedTable from './tables/AdvancedTables';
import AsynchronousTable from './tables/AsynchronousTable';
import Banners from './ui/banners';
import Drags from './ui/Draggable';
import UserInfo from './userInfo/index';
import PVUV from './userInfo/PVUV';
import AuthBasic from './auth/Basic';
import RouterEnter from './auth/RouterEnter';
import MultipleMenu from './extension/MultipleMenu';
import Sub1 from './smenu/Sub1';
import Sub2 from './smenu/Sub2';
import Env from './env';
import ThemeManage from './themeManage/index';
import AiQuery from './dataManage/AiQuery';
import Base from './dataManage/Base';
import Expert from './dataManage/Expert';
import FunOperation from './dataManage/FunOperation';
import UserOperation from './dataManage/UserOperation';
import SaveManage from './dataManage/saveManage';
import Home from './home/index';
import ConsumeOrder from './order/ConsumeOrder';
import PointOrder from './order/pointOrder';
import RequireManage from './requireManage/RequireManage';

const WysiwygBundle = Loadable({
    // 按需加载富文本配置
    loader: () => import('./ui/Wysiwyg'),
    loading: Loading,
});

export default {
    BasicTable,
    AdvancedTable,
    AsynchronousTable,
    Banners,
    Drags,
    UserInfo,
    PVUV,
    AuthBasic,
    RouterEnter,
    WysiwygBundle,
    MultipleMenu,
    Sub1,
    Sub2,
    Env,
    ThemeManage,
    AiQuery,
    Base,
    Expert,
    FunOperation,
    UserOperation,
    SaveManage,
    Home,
    ConsumeOrder,
    PointOrder,
    RequireManage,
} as any;
