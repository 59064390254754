import httpAjax from '../utils/httpAjax';

/**
 * 获取消费订单
 */
export const GetOrdernInfos = () => {
    return httpAjax({
        url: `/chart/orders/queryOrder`,
        method: 'get',
    });
};
/**
 * 获取智点消耗订单
 */
export const GetPotinOrder = (params: any) => {
    return httpAjax({
        url: `/user/coinLog/queryAction`,
        method: 'post',
        data: params,
    });
};
