/**
 * 用户数据统计
 */
import React, { useState } from 'react';
import { Row, Col, Card, Timeline, Table, Button, DatePicker } from 'antd';
import BreadcrumbCustom from '../widget/BreadcrumbCustom';
import { GetChartUseData, ExportStatsData } from '../../api/stats';
import { downloadFile } from '../../utils/common';
import moment from 'moment';
import 'moment/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/zh_CN';

const { RangePicker } = DatePicker;

class Base extends React.Component {
    state = {
        selectOptions: [
            {
                label: '',
                value: '',
            },
        ],
        columns: [],
        saveTableData: [],
        exportTableData: [],
        transmitTableData: [],
        userTableData: [],
        startTime: '',
        endTime: '',
        totalUser: 0,
        newUser: 0,
        visible: false,
        setIsModalOpen: false,
        selectedList: [],
        selectedRows: [],
        expirationTime: moment().add(1, 'days').format('YYYY-MM-DD HH:MM:SS'),
    };
    // 获取图表使用统计数据
    async getChartUseData(params: any) {
        const { code, data } = await GetChartUseData(params);
        if (code === 200) {
            return data;
        }
    }
    // 获取表格表头数据(保存/导出)
    getTableColumns(data: any) {
        const newColumns: any = [
            {
                title: '项目名字',
                dataIndex: 'type',
                fixed: 'left',
                width: 70,
            },
        ];
        data.forEach((item: { name: any; configId: any }) => {
            const columnsItem = {
                title: item.name,
                dataIndex: `chart${item.configId}`,
                width: 100,
            };
            newColumns.push(columnsItem);
        });
        this.setState({
            columns: newColumns,
        });
    }
    handleChartUseData(data: any) {
        let PV = {
            type: 'PV',
        };
        let UV = {
            type: 'UV',
        };
        data.forEach((item: { configId: any; pv: any; uv: any }) => {
            (PV as any)[`chart${item.configId}`] = item.pv;
            (UV as any)[`chart${item.configId}`] = item.uv;
        });
        let _saveData = [PV, UV];
        return _saveData;
    }
    // 获取保存操作的统计数据
    async getSaveChartUseData() {
        const params = {
            startTime: this.state.startTime,
            endTime: this.state.endTime,
            event: 1,
            eventType: 0,
        };
        const data = await this.getChartUseData(params);
        this.getTableColumns(data);
        this.setState({
            saveTableData: this.handleChartUseData(data),
        });
    }
    // 获取用户导出操作的统计数据
    async getExportChartUseData() {
        const params = {
            startTime: this.state.startTime,
            endTime: this.state.endTime,
            event: 2,
            eventType: 0,
        };
        const data = await this.getChartUseData(params);
        this.setState({
            exportTableData: this.handleChartUseData(data),
        });
    }
    // 导出用户操作记录数据
    async exportStatsData(event: number, eventType: number, fileName: string = '导出用户数据') {
        const { startTime, endTime } = this.state;
        const params = {
            startTime,
            endTime,
            event,
            eventType,
        };
        const res = await ExportStatsData(params);
        fileName = `${startTime}~${endTime}${fileName}`;
        downloadFile(res, fileName, '.xlsx');
    }

    handleDateSelect = (date: any, dateString: any) => {
        // console.log(dateString);
        this.setState({
            startTime: dateString[0],
            endTime: dateString[1],
        });
    };

    searchAllByDate() {
        this.getSaveChartUseData();
        this.getExportChartUseData();
    }

    setSelectionType = (selectedRowKeys: any, selectedRows: any) => {
        console.log(selectedRowKeys);
        console.log(selectedRows);
        let list: { label: any; value: any }[] = [];
        let _selectedList: any[] = [];
        selectedRows.forEach((item: { nickName: any; userId: any }) => {
            list.push({
                label: item.nickName,
                value: item.userId,
            });
            _selectedList.push(item.userId);
        });
        this.setState({
            selectOptions: list,
            selectedList: _selectedList,
            selectedRows: selectedRowKeys,
        });
    };
    async componentDidMount() {
        this.searchAllByDate();
    }
    handleOk = () => {};
    // handleSelectChange = () => {

    // }

    render() {
        return (
            <div className="gutter-example button-demo">
                {/* <BreadcrumbCustom /> */}
                <Row gutter={16}>
                    <Col className="gutter-row" md={24}>
                        <Card>
                            <div>
                                <span style={{ marginRight: '10px' }}>日期：</span>
                                <RangePicker
                                    onChange={this.handleDateSelect}
                                    locale={locale}
                                    ranges={{
                                        今天: [moment(), moment()],
                                        本月: [moment().startOf('month'), moment().endOf('month')],
                                        本周: [moment().startOf('week'), moment().endOf('week')],
                                    }}
                                />
                                <Button
                                    style={{ marginLeft: '20px' }}
                                    onClick={() => this.searchAllByDate()}
                                >
                                    查询
                                </Button>
                            </div>
                        </Card>
                    </Col>
                    <Col className="gutter-row" md={24}>
                        <div className="gutter-box">
                            <Card
                                title="基础制图保存统计"
                                extra={
                                    <Button
                                        type="primary"
                                        onClick={() =>
                                            this.exportStatsData(1, 0, '用户保存统计数据')
                                        }
                                    >
                                        导出
                                    </Button>
                                }
                                bordered={false}
                            >
                                <Table
                                    tableLayout="auto"
                                    pagination={false}
                                    scroll={{ x: 1200 }}
                                    columns={this.state.columns}
                                    dataSource={this.state.saveTableData}
                                />
                            </Card>
                        </div>
                    </Col>
                    <Col className="gutter-row" md={24}>
                        <div className="gutter-box">
                            <Card
                                title="基础制图导出统计"
                                extra={
                                    <Button
                                        type="primary"
                                        onClick={() =>
                                            this.exportStatsData(2, 0, '用户导出统计数据')
                                        }
                                    >
                                        导出
                                    </Button>
                                }
                                bordered={false}
                            >
                                <Table
                                    tableLayout="auto"
                                    pagination={false}
                                    scroll={{ x: 1200 }}
                                    columns={this.state.columns}
                                    dataSource={this.state.exportTableData}
                                />
                            </Card>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default Base;
