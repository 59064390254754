import React, { useState, useEffect } from 'react';
import BreadcrumbCustom from '../widget/BreadcrumbCustom';
import { Row, Col, Card, Table, Button, Modal, message, Input, Select, DatePicker } from 'antd';
import { GetOrdernInfos, GetPotinOrder } from '../../api/order';
import locale from 'antd/es/date-picker/locale/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';
// import value from './themeTest';

const { RangePicker } = DatePicker;
const { TextArea } = Input;
const PointOrder = () => {
    const columns = [
        {
            title: '手机号',
            dataIndex: 'phonenumber',
            key: 'phonenumber',
        },
        // {
        //     title: '用户Id',
        //     dataIndex: 'userId',
        //     key: 'userId',
        // },
        // {
        //     title: '订单号',
        //     dataIndex: 'orderNum',
        //     key: 'orderNum',
        // },
        {
            title: '订单详情',
            dataIndex: 'actionInfo',
            key: 'actionInfo',
        },
        {
            title: '订单时间',
            dataIndex: 'createdAt',
            key: 'createdAt',
        },
        {
            title: '智点数量',
            dataIndex: 'coins',
            key: 'coins',
        },
    ];
    const userActionOption = [
        {
            value: 'aiDeduct',
            label: 'ai制图导出',
        },
        {
            value: 'baseDeduct',
            label: '基础制图-高清导出',
        },
        {
            value: 'expertDeduct',
            label: '专家图例',
        },
        {
            value: 'fileDeduct',
            label: '可保存文件数扩容',
        },
    ];
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [tableData, setTableData] = useState<any>([]);
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [action, setAction] = useState('');

    useEffect(() => {
        getOrdernInfos();
    }, []);

    const handleAction = (val: string) => {
        setAction(val);
    };
    const handleDateSelect = (date: any, dateString: any) => {
        // console.log('output->',dateString);
        setStartTime(dateString[0]);
        setEndTime(dateString[1]);
        // setAction(val)
    };
    const getOrdernInfos = async () => {
        try {
            const params = {
                startTime,
                endTime,
                phoneNumber,
                action,
            };
            const { code, data } = await GetPotinOrder(params);
            if (code == 200) {
                setTableData(data);
            }
        } catch (error) {
            console.log('output->', error);
        }
    };
    const checkboxChage = (newSelectedRowKeys: any, newSelectedRows: any) => {
        setSelectedRowKeys(newSelectedRowKeys);
        console.log(newSelectedRows[0]);
    };

    return (
        <div className="gutter-example button-demo">
            {/* <BreadcrumbCustom breads={['主题管理']} /> */}
            <Row gutter={16}>
                <Col md={24}>
                    <Card title="智点消耗订单" bordered={false}>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginBottom: '20px',
                            }}
                        >
                            <div>
                                <span style={{ marginRight: '10px' }}>日期：</span>
                                <RangePicker
                                    onChange={handleDateSelect}
                                    locale={locale}
                                    ranges={{
                                        今天: [moment(), moment()],
                                        本月: [moment().startOf('month'), moment().endOf('month')],
                                        本周: [moment().startOf('week'), moment().endOf('week')],
                                    }}
                                />
                            </div>
                            <span style={{ marginLeft: '20px' }}>订单详情：</span>
                            <Select
                                allowClear
                                style={{ width: '200px' }}
                                placeholder="选择订单类型"
                                onChange={handleAction}
                                options={userActionOption}
                            />
                            <span style={{ marginLeft: '20px' }}>手机号：</span>
                            <Input
                                allowClear
                                style={{ width: '180px' }}
                                placeholder="请输入手机号"
                                onChange={(e) => setPhoneNumber(e.target.value)}
                            />
                            <Button style={{ marginLeft: '20px' }} onClick={() => getOrdernInfos()}>
                                查询
                            </Button>
                        </div>
                        <Table
                            columns={columns}
                            dataSource={tableData}
                            rowSelection={{
                                type: 'checkbox',
                                onChange: checkboxChage,
                                selectedRowKeys,
                            }}
                        />
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default PointOrder;
